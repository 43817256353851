export const AUTH_CONST = {
  USER_INFO: 'user_info',
  LOCAL_STORAGE_AUTH: 'isAuth',
  LOCAL_STORAGE_TEMPORARY_AUTH: 'isTemporaryAuth',
  LIST_OF_EMAIL_AUTHENTICATED: 'list_of_email_authenticated',
  CURRENT_ACCOUNT: 'current_account',
  HAS_WORKSPACE: 'has_workspace',
  REGISTER_REMINDER: 'Enter your email to create an account',
  CURRENT_PATH: 'current_path',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  NOT_FOUND: 'NOT_FOUND'
};

export const ERROR_AUTH: Record<string, string> = {
  ALREADY_HAS_ACCOUNT: 'Already has account'
};

export const LOGIN_PAGE = {
  HEADER: 'Sign in to your account',
  REGISTER: 'not already an account yet'
};

export const FORGOT_PASSWORD = {
  UNAUTHORIZED: 'Your password reset link has expired, Please try other link.'
};

export enum USER_ROLES {
  SYSTEM_ADMIN = 'SystemAdmin'
}

export const PATH = {
  ManageDeposit: 'deposit-management',
  ManageWithdrawal: 'withdraw-management',
  ManageTransaction: 'approve-transaction-management',
  ManageReconciliationFile: 'report-files-management',
  ManageAccounts: 'accounts-management',
  AnnounceInformation: 'announced-information-management',
  ManageBanners: 'banners-management',
  ManageFaqCategory: 'faq-category-management',
  ManageFaqPost: 'faq-article-management',
};

export const PERMISSIONS_KEY = {
  Dashboard: 'Dashboard',
  ManageDeposit: 'ManageDeposit',
  ManageWithdrawal: 'ManageWithdrawal',
  ManageTransaction: 'ManageTransaction',
  ManageReconciliationFile: 'ManageReconciliationFile',
  ManageAccounts: 'ManageAccounts',
  AnnounceInformation: 'AnnounceInformation',
  ManageBanners: 'ManageBanners',
  ManageFaqCategory: 'ManageFaq_All',
  ManageFaqPost: 'ManageFaq_All',
  ManageReferralCode: 'ManageReferralCode',
  ManageDE: 'ManageDE'
};
