import { MdClose } from 'assets/icons';
import Breadcrumb from 'components/Breadcrumb';
import TitlePage from 'components/TitlePage';
import { CircleSpin } from 'components/UiComponents';
import Modal from 'components/UiComponents/Modal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { GiTwoCoins } from 'react-icons/gi';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import {
  approveWithDrawAsync,
  getWithdrawDetailsAsync
} from 'store/slices/withdraws/withdrawsSlice';
import { toast } from 'react-toastify';
import { PermissionsGate } from 'components/PermissionsGate';
import { useAuth } from 'contexts/Auth/AuthContext';
import WarningIcon from 'assets/images/warning-icon.png';
import { PERMISSIONS_KEY } from 'constant';

const WithdrawManagementDetails = () => {
  const [crumbs, setCrumbs] = useState<any>();
  const navigate = useNavigate();
  const paramsURL = useParams();
  const [loading, setLoading] = useState(true);
  const [withdrawDetails, setWithdrawDetails] = useState<any>();
  const dispatch = useAppDispatch();
  const [adjustCashModal, setAdjustCashModal] = useState<any>();
  const [submitAdjustCash, setSubmitAdjustCash] = useState<any>();
  const [adjustCashErrorMessage, setAdjustCashErrorMessage] = useState<any>();
  const [reloadPage, setReloadPage] = useState<any>();
  const [confirmModal, setConfirmModal] = useState<any>();
  const [submitConfirm, setSubmitConfirm] = useState<any>();
  const [bankTransactionID, setBankTracsactionID] = useState<any>();
  const [bankRefNo, setBankRefNo] = useState<any>();
  const [refundDepositCheckBox, SetRefundDepositCheckBox] = useState<any>();
  const [confirmErrorMessage, setConfirmErrorMessage] = useState<any>();
  const [cancelWithdrawModal, setCancelWithdrawModal] = useState<any>();
  const [submitCancelWithdraw, setSubmitCancelWithdraw] = useState<any>();
  const [approveWithdrawModal, setApproveWithDrawModal] = useState<any>();
  const [submitApproveWithdraw, setSubmitApproveWithdraw] = useState<any>();
  const [approvePartnerId] = useState<any>();
  const auth = useAuth();
  const roles = ['Admin', 'Accountant', 'SystemAdmin', 'SupperCS'];

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    setCrumbs([
      { name: 'Giao Dịch Rút Tiền', url: '/withdraw-management' },
      {
        name: 'Chi Tiết Giao Dịch Rút Tiền',
        url: `/withdraw-management/${paramsURL.id}`
      }
    ]);
    const getWithdrawById = async () => {
      setLoading(true);
      const id = paramsURL?.id + '';
      const response = await dispatch(getWithdrawDetailsAsync(id));
      setWithdrawDetails(response.payload);
      setLoading(false);
    };

    getWithdrawById();
  }, [reloadPage]);

  const handleSubmitAdjust = async () => {};

  const handleConfirmSubmit = async () => {};

  const handleOnclickConfirmButton = () => {
    setBankRefNo(withdrawDetails?.bank_ref_no);
    setBankTracsactionID(withdrawDetails?.bank_transaction_id);
    setConfirmModal(true);
  };

  const handleRefundDepositCheckBox = () => {
    if (refundDepositCheckBox == 'refundWithdrawCheckBox') {
      SetRefundDepositCheckBox('');
    } else {
      SetRefundDepositCheckBox('refundWithdrawCheckBox');
    }
    setConfirmErrorMessage('');
  };

  const handleRefundedDepositCheckBox = () => {
    if (refundDepositCheckBox == 'refundedWithdrawCheckBox') {
      SetRefundDepositCheckBox('');
    } else {
      SetRefundDepositCheckBox('refundedWithdrawCheckBox');
    }
    setConfirmErrorMessage('');
  };

  const handleCancelWithdraw = async () => {};

  const handleApproveWithdraw = async () => {
    setSubmitApproveWithdraw(true);
    const response = await dispatch(approveWithDrawAsync(paramsURL?.id || ''));
    if (response?.payload?.status === 'Success') {
      setWithdrawDetails(response?.payload?.data);
      toast.success('Duyệt giao dịch thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitApproveWithdraw(false);
      setApproveWithDrawModal(false);
    } else {
      let message = response?.payload?.error_message;
      toast.error(message || 'Có lỗi, vui lòng kiểm tra lại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setSubmitApproveWithdraw(false);
      setApproveWithDrawModal(false);
    }
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <TitlePage
        icon={() => <GiTwoCoins />}
        name={'Mã Lệnh: ' + withdrawDetails?.transfer_id}
      />
      {loading ? (
        <>
          <div className="flex justify-center mt-4 items-center tex-sm">
            <CircleSpin color="text-primary-e2" /> Loading...
          </div>
        </>
      ) : (
        <div className="mt-10">
          <form className="form">
            <div className="flex w-full h-auto">
              <div className="w-3/4 mr-5">
                <div className="py-6 px-6 bg-white rounded-xl border border-solid border-gray-e0">
                  <div className="text-2xl font-semibold text flex items-center border-b border-solid border-gray-bd py-5">
                    <span>Thông Tin Tài Khoản</span>
                  </div>
                  <div className="py-5">
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">
                        Tên Khách Hàng:
                      </div>
                      <div className="w-2/3 font-semibold">
                        {withdrawDetails?.full_name}
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">
                        Số Điện Thoại:
                      </div>
                      <div className="w-2/3 font-semibold">
                        {withdrawDetails?.phone_number}
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">
                        Email:
                      </div>
                      <div className="w-2/3 font-semibold">
                        {withdrawDetails?.email}
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">
                        Username:
                      </div>
                      <div className="w-2/3 font-semibold">
                        {withdrawDetails?.user_name}
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">
                        User ID:
                      </div>
                      <div className="w-2/3 font-semibold">
                        {withdrawDetails?.user_id}
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">
                        Mã Khách Hàng:
                      </div>
                      <div className="w-2/3 font-semibold">
                        {withdrawDetails?.account_no}
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">
                        Loại Tài Khoản:
                      </div>
                      <div className="w-2/3 font-semibold">
                        {withdrawDetails?.account_type}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-6 px-6 bg-white rounded-xl border border-solid border-gray-e0 mt-5">
                  <div className="text-2xl font-semibold text flex items-center border-b border-solid border-gray-bd py-5">
                    <span>Thông Tin Giao Dịch Rút Tiền</span>
                  </div>
                  <div className="py-5">
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">{`Số Tiền Rút (đ):`}</div>
                      <div className="w-2/3 font-semibold">
                        <CurrencyFormat
                          value={withdrawDetails?.transfer_amount_request}
                          thousandSeparator={true}
                          displayType={'text'}
                        />
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">{`Tổng Số Phí (đ):`}</div>
                      <div className="w-2/3 font-semibold">
                        <CurrencyFormat
                          value={withdrawDetails?.transfer_fee}
                          thousandSeparator={true}
                          displayType={'text'}
                        />
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">{`Số Tiền Thực Nhận (đ):`}</div>
                      <div className="w-2/3 font-semibold">
                        <CurrencyFormat
                          value={withdrawDetails?.transfer_amount_request}
                          thousandSeparator={true}
                          displayType={'text'}
                        />
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">
                        Ngân Hàng:
                      </div>
                      <div className="w-2/3 font-semibold">
                        {withdrawDetails?.bank_transfer_to || ''}
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">
                        Số Tài Khoản:
                      </div>
                      <div className="w-2/3 font-semibold">
                        {withdrawDetails?.transfer_to}
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">
                        Bank Transaction ID:
                      </div>
                      <div className="w-2/3 font-semibold">
                        {withdrawDetails?.bank_transfer_id}
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">
                        Bank Ref No:
                      </div>
                      <div className="w-2/3 font-semibold">
                        {withdrawDetails?.bank_ref_no}
                      </div>
                    </div>
                    <div className="flex py-2">
                      <div className="w-1/3 text-gray-82 font-medium">
                        Ngày Rút Tiền:
                      </div>
                      <div className="w-2/3 font-semibold">
                        {moment(new Date(withdrawDetails?.created_date)).format(
                          'DD-MM-YYYY HH:mm:ss'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/4 h-auto">
                <div className="bg-white rounded-xl h-auto w-full py-6 px-6 border border-solid border-gray-e0">
                  <div className="text-2xl font-semibold text flex items-center border-b border-solid border-gray-bd py-5">
                    <span>Trạng Thái</span>
                  </div>
                  <div className="py-5">
                    <div className="flex py-2">
                      <div className="w-1/2">Rút Tiền:</div>
                      <div
                        className={`w-1/2 withdraw withdraw-${withdrawDetails?.status}`}
                      >
                        {withdrawDetails?.status}
                      </div>
                    </div>
                    <PermissionsGate name={PERMISSIONS_KEY.ManageWithdrawal}>
                      <div className="mt-10 flex items-center">
                        {withdrawDetails?.reconciliation_type == 1 && (
                          <button
                            className="form-button w-full"
                            type="button"
                            onClick={() => setAdjustCashModal(true)}
                          >
                            Hoàn Tiền
                          </button>
                        )}
                        {withdrawDetails?.reconciliation_type == 2 && (
                          <button
                            className="form-button w-full"
                            type="button"
                            onClick={handleOnclickConfirmButton}
                          >
                            Đối Soát
                          </button>
                        )}
                      </div>
                      {withdrawDetails?.status === 'PendingApproval' && (
                        <div className="flex items-center gap-5">
                          <button
                            className="btn text-sm w-1/2"
                            type="button"
                            onClick={() => setApproveWithDrawModal(true)}
                          >
                            Duyệt
                          </button>
                          <button
                            className="btn text-sm w-1/2"
                            type="button"
                            onClick={() => setCancelWithdrawModal(true)}
                          >
                            Hủy
                          </button>
                        </div>
                      )}

                      {withdrawDetails?.approval_note && (
                        <div className="form-group">
                          <label htmlFor="approval_note">Ghi chú</label>
                          <textarea
                            placeholder="Nhập ghi chú"
                            className="form-area"
                            name="approval_note"
                            id="approval_note"
                            disabled
                            readOnly
                            rows={4}
                            cols={50}
                            value={withdrawDetails?.approval_note}
                          ></textarea>
                        </div>
                      )}
                      {adjustCashErrorMessage && (
                        <div className="flex justify-center">
                          <p
                            className="form-error"
                            dangerouslySetInnerHTML={{
                              __html: adjustCashErrorMessage
                            }}
                          ></p>
                        </div>
                      )}
                    </PermissionsGate>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      <Modal
        show={adjustCashModal}
        title={'Xác Nhận Hoàn Tiền Giao Dịch Này!'}
        onClose={() => {
          setAdjustCashModal(false);
        }}
        className="max-w-[420px]"
      >
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex justify-center w-full md:w-52 form-button-seconday"
            onClick={() => {
              setAdjustCashModal(false);
            }}
          >
            Huỷ
          </button>
          <button
            type="button"
            className="flex justify-center w-full md:w-52 form-button"
            disabled={submitAdjustCash}
            onClick={handleSubmitAdjust}
          >
            {submitAdjustCash && <CircleSpin />}
            Đồng ý
          </button>
        </div>
      </Modal>
      <Modal
        show={cancelWithdrawModal}
        title={'Xác Nhận Hủy Giao Dịch Này!'}
        onClose={() => {
          setCancelWithdrawModal(false);
        }}
        className="max-w-[420px]"
      >
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex justify-center w-full form-button-seconday"
            onClick={() => {
              setCancelWithdrawModal(false);
            }}
          >
            Huỷ
          </button>
          <button
            type="button"
            className="flex justify-center w-full form-button"
            disabled={submitCancelWithdraw}
            onClick={handleCancelWithdraw}
          >
            {submitCancelWithdraw && <CircleSpin />}
            Đồng ý
          </button>
        </div>
      </Modal>
      <Modal
        show={approveWithdrawModal}
        title={''}
        onClose={() => {
          setApproveWithDrawModal(false);
        }}
        className="max-w-[720px] modal-confirm"
      >
        <div className="mb-3 font-medium text-[20px] flex">
          <div>
            <img
              src={WarningIcon}
              width={48}
              height={48}
              alt="Xác nhận duyệt giao dịch này?"
            />
            <div className="mt-10">Xác nhận duyệt giao dịch này?</div>
          </div>
        </div>
        <div className="pt-1 pb-[66px]">
          <div className="bg-[#F2F4F7] py-3 px-[14px] text-[#667085] rounded-lg">
            <div>Tên KH: {withdrawDetails?.full_name}</div>
            <div className="mb-3"> Mã KH: {withdrawDetails?.account_no}</div>
            <div>
              Số tiền rút:&nbsp;
              <CurrencyFormat
                value={withdrawDetails?.transfer_amount_request}
                thousandSeparator={true}
                displayType={'text'}
              />
            </div>
          </div>
        </div>
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex justify-center w-full form-button-seconday"
            onClick={() => {
              setApproveWithDrawModal(false);
            }}
          >
            Đóng
          </button>
          <button
            type="button"
            className="flex justify-center w-full form-button"
            disabled={submitApproveWithdraw}
            onClick={handleApproveWithdraw}
          >
            {submitApproveWithdraw && <CircleSpin />}
            Xác nhận
          </button>
        </div>
      </Modal>
      <Modal
        show={confirmModal}
        title={''}
        onClose={() => {
          setConfirmErrorMessage('');
          setConfirmModal(false);
        }}
        className="max-w-[720px]"
      >
        <div className="mb-3 font-extrabold text-lg flex">
          <div>
            <span>Đối Soát Giao Dịch Rút Tiền</span>
          </div>
          <div>
            <button
              type="button"
              className="absolute right-4 lg:right-10 top-7"
              onClick={() => {
                setConfirmErrorMessage('');
                setConfirmModal(false);
              }}
            >
              <MdClose />
            </button>
          </div>
        </div>
        <div className="py-6">
          <div className="flex form-group">
            <div className="w-1/3">
              <span>Trạng Thái:</span>
            </div>
            <div className="w-2/3 flex items-center">
              <input
                type="checkbox"
                className="form-checkbox mr-5"
                name="refundedWithdrawCheckBox"
                checked={refundDepositCheckBox == 'refundedWithdrawCheckBox'}
                onChange={handleRefundedDepositCheckBox}
              />
              <label htmlFor="">
                <span className="text-[#009ef7] font-semibold">
                  Không hoàn tiền
                </span>
                {` (Đã trừ tiền ngân hàng bên Bulls)`}
              </label>
            </div>
          </div>
          <div className="flex form-group">
            <div className="w-1/3"></div>
            <div className="w-2/3 flex items-center">
              <input
                type="checkbox"
                className="form-checkbox mr-5"
                name="refundWithdrawCheckBox"
                checked={refundDepositCheckBox == 'refundWithdrawCheckBox'}
                onChange={handleRefundDepositCheckBox}
              />
              <label htmlFor="">
                <span className="text-[#009ef7] font-semibold">Hoàn tiền</span>
                {` (Chưa trừ tiền ngân hàng bên Bulls)`}
              </label>
            </div>
          </div>
          <div className="flex form-group">
            <div className="w-1/3 flex">
              <span className="self-center">Bank Transaction ID:</span>
            </div>
            <div className="w-2/3 flex items-center justify-center">
              <input
                placeholder="Nhập Bank Transaction ID nếu có!"
                className="form-control"
                name="bankTransactionIDInput"
                id="bankTransactionIDInput"
                value={bankTransactionID}
                onChange={(value: any) =>
                  setBankTracsactionID(value.target.value)
                }
              ></input>
            </div>
          </div>
          <div className="flex form-group">
            <div className="w-1/3 flex">
              <span className="self-center">Bank Ref No:</span>
            </div>
            <div className="w-2/3 flex items-center justify-center">
              <input
                placeholder="Nhập Bank Ref No nếu có!"
                className="form-control"
                name="bankRefNoInput"
                id="bankRefNoInput"
                value={bankRefNo}
                onChange={(value: any) => setBankRefNo(value.target.value)}
              ></input>
            </div>
          </div>
        </div>
        {confirmErrorMessage && (
          <div className="flex justify-center">
            <p
              className="form-error"
              dangerouslySetInnerHTML={{ __html: confirmErrorMessage }}
            ></p>
          </div>
        )}
        <div className="flex space-x-4 items-center justify-center">
          <button
            type="button"
            className="flex justify-center w-full form-button-seconday"
            onClick={() => {
              setConfirmErrorMessage('');
              setConfirmModal(false);
            }}
          >
            Huỷ
          </button>
          <button
            type="submit"
            className="flex justify-center w-full form-button"
            disabled={submitConfirm}
            onClick={() => handleConfirmSubmit()}
          >
            {submitConfirm && <CircleSpin />}
            Đồng ý
          </button>
        </div>
      </Modal>
    </>
  );
};

export default WithdrawManagementDetails;
