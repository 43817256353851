import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { activeUserById, approveEkycUserById, changeStatusEkycUserById, getBondTransactionsById, getCashWaitingToReceiveById, getDepositsUserById, getFSInternalOrdersById, getFSStockAdvanceOrdersById, getStockWaitingToReceiveById, getUserById, getUsers, getWithdrawsUserById, lockUserById, restoreUserById } from "./usersAPI";

export interface UsersState {
    users: any;
    status: 'idle' | 'loading' | 'failed';
}
  
const initialState: UsersState = {
    users: [],
    status: 'idle',
};

export const getUsersAsync = createAsyncThunk(
    'users/getUsers',
    async (params?: any) => {
      const response = await getUsers(params);
      return response.data;
    }
);

export const getUserByIdAsync = createAsyncThunk(
    'users/getUserById',
    async (params?: any) => {
        const response = await getUserById(params);
        return response.data;
    }
);

export const changeStatusEkycUserByIdAsync = createAsyncThunk(
    'users/reEkycUserById',
    async (params?: any) => {
        try {
            const response = await changeStatusEkycUserById(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        }
    }
);

export const activeUserByIdAsync = createAsyncThunk(
    'users/activeUserById',
    async (params?: any) => {
        try {
            const response = await activeUserById(params);
            return response.data;
        }catch (error: any) {
            return {error: error.response?.data}
        }
    }
);

export const lockUserByIdAsync = createAsyncThunk(
    'users/lockUserById',
    async (params?: any) => {
        try {
            const response = await lockUserById(params);
            return response.data;
        }catch (error: any) {
            return {error: error.response?.data}
        }
    }
);

export const approveEkycUserByIdAsync = createAsyncThunk(
    'users/approveEkycUserById',
    async (params?: any) => {
        try {
            const response = await approveEkycUserById(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        }
    }
);

export const getDepositsUserByIdAsync = createAsyncThunk(
    'users/getDepositsUserById',
    async (params?: any) => {
        const response = await getDepositsUserById(params);
        return response.data;
    }
);

export const getWithdrawsUserByIdAsync = createAsyncThunk(
    'users/getWithdrawsUserById',
    async (params?: any) => {
        const response = await getWithdrawsUserById(params);
        return response.data;
    }
);

export const restoreUserbyIdAsync = createAsyncThunk(
    'users/restoreUserbyId',
    async (params?: any) => {
        try {
            const response = await restoreUserById(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        }
    }
);

export const getFSInternalOrdersByIdAsync = createAsyncThunk(
    'users/getFSInternalOrdersById',
    async (params?: any) => {
        try {
            const response = await getFSInternalOrdersById(params);
            return response.data;
        }catch (error: any) {
            return {error: error.response?.data}
        }
    }
);

export const getFSStockAdvanceOrdersByIdAsync = createAsyncThunk(
    'users/getFSStockAdvanceOrdersById',
    async (params?: any) => {
        try {
            const response = await getFSStockAdvanceOrdersById(params);
            return response.data;
        }catch (error: any) {
            return {error: error.response?.data}
        }
    }
);

export const getStockWaitingToReceiveByIdAsync = createAsyncThunk(
    'users/getStockWaitingToReceiveById',
    async (params?: any) => {
        try {
            const response = await getStockWaitingToReceiveById(params);
            return response.data;
        }catch (error: any) {
            return {error: error.response?.data}
        }
    }
);

export const getCashWaitingToReceiveByIdAsync = createAsyncThunk(
    'users/getCashWaitingToReceiveById',
    async (params?: any) => {
        try {
            const response = await getCashWaitingToReceiveById(params);
            return response.data;
        }catch (error: any) {
            return {error: error.response?.data}
        }
    }
);

export const getBondTransactionsByIdAsync = createAsyncThunk(
    'users/getBondTransactionsById',
    async (params?: any) => {
        try {
            const response = await getBondTransactionsById(params);
            return response.data;
        }catch (error: any) {
            return {error: error.response?.data}
        }
    }
);

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
            state.users = action.payload;
          }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsersAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUsersAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.users = action.payload;
            })      
    },
});

export const { getList } = usersSlice.actions;
export const selectUsers = (state: RootState) => state.users;

export default usersSlice.reducer;