import Breadcrumb from 'components/Breadcrumb';
import TitlePage from 'components/TitlePage';
import { CircleSpin, ItemsPerPage } from 'components/UiComponents';
import FilterButton from 'components/UiComponents/FilterButton/FilterButton';
import PanelSiderBar from 'components/UiComponents/PanelSiderBar/PanelSiderBar';
import SearchButton from 'components/UiComponents/SearchButton/SearchButton';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineBook } from 'react-icons/ai';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  deleteAnnouncementAsync,
  getAnnouncementAsync,
  selectAnnouncement
} from 'store/slices/announcement/announcementSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { FORM_FORMAT } from 'constant/form.constant';
import moment from 'moment';
import { PaginationOwn } from 'components/Shared';
import { BiEdit, BiTrash } from 'react-icons/bi';
import Modal from 'components/UiComponents/Modal';
import { toast } from 'react-toastify';
import { PermissionsGate } from 'components/PermissionsGate';
import { PERMISSIONS_KEY } from 'constant';
import { PERMISSIONS_ACTIONS } from 'hooks';

const AnnouncedInformationPage = () => {
  const { register, handleSubmit, trigger, reset } = useForm<any>();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [reloadPage, setReloadPage] = useState<any>();
  const [openedFilter, setOpenedFilter] = useState(false);
  const [itemPerPage, setItemPerPage] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<any>({});
  const { annoncement } = useAppSelector(selectAnnouncement);
  const [fromDate, setStart] = useState<any>();
  const [toDate, setEnd] = useState<any>();
  const [idAnnouncement, setIdAnnouncement] = useState<any>();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [submitDelete, setSubmitDelete] = useState(false);

  const [crumbs] = useState([
    {
      name: 'Quản Lý Thông Tin Công Bố',
      url: '/announced-information-management'
    }
  ]);

  const page = searchParams.get('page') || 1;

  const selected = (url: any) => {
    if (url) {
      navigate(url);
    } else {
      navigate(-1);
    }
  };

  const getData = async (params: any) => {
    setLoading(true);
    await dispatch(getAnnouncementAsync(params));
    setLoading(false);
  };

  useEffect(() => {
    if (page) {
      setCurrentPage(Number(page));
      let params = {
        ...filters,
        page_index: page,
        page_size: itemPerPage
      };
      if (keyword) {
        params = {
          ...params,
          search_key: keyword
        };
      }
      getData(params);
    }
  }, [filters, itemPerPage, keyword, page, reloadPage]);

  const onSearch = (reset?: boolean) => {
    navigate(`/announced-information-management`);
    const newKeywork = reset ? '' : keyword?.trim();
    setKeyword(newKeywork);
    setReloadPage(!reloadPage);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
      onSearch();
    }
  };

  const toggleFilter = () => {
    setOpenedFilter(!openedFilter);
  };

  const handlePageSizeChange = (value: any) => {
    navigate(`/announced-information-management`);
    setItemPerPage(value);
  };

  const submitHandler: SubmitHandler<any> = async value => {
    let filerValues = value;
    if (filerValues.announcement_type === '') {
      delete filerValues['announcement_type'];
    }
    if (fromDate) {
      const from_date = moment(fromDate)
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      filerValues = {
        ...filerValues,
        from_date
      };
    }
    if (toDate) {
      const to_date = moment(toDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      filerValues = {
        ...filerValues,
        to_date
      };
    }

    setOpenedFilter(false);
    navigate(`/announced-information-management`);
    setFilters(filerValues);
  };

  const clearFilter = () => {
    reset();
    setStart('');
    setEnd('');
    setFilters({});
    setOpenedFilter(false);
    navigate(`/announced-information-management`);
  };

  const onPageChange = useCallback(async (event: number) => {
    navigate(`/announced-information-management?page=${event}`);
  }, []);

  const handleDeleteAnnouncement = async (id: any) => {
    setSubmitDelete(true);
    const res = await dispatch(deleteAnnouncementAsync({ id }));
    if (res?.payload?.status === 'Success') {
      setSubmitDelete(false);
      setConfirmDeleteModal(false);
      setReloadPage(!reloadPage);
      toast.success('Xóa thông tin công bố thành công!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } else {
      setSubmitDelete(false);
      setConfirmDeleteModal(false);
      toast.error('Xóa thông tin công bố thất bại!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  const announcementType = (type: string) => {
    switch (type) {
      case 'FinancialReport':
        return 'Báo cáo tài chính';
      case 'Announcement':
        return 'Công bố thông tin';
      case 'CompanyDocument':
        return 'Tài liệu công ty';
      case 'DocumentReport':
        return 'Tài liệu báo cáo';
      case 'SbbsReport':
        return 'Thông báo';
      case 'MarginSecurity':
        return 'Giao Dịch Ký Quỹ';
      default:
        return '-';
    }
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <div className="flex justify-between items-center">
        <TitlePage
          icon={() => <AiOutlineBook />}
          name="Quản Lý Thông Tin Công Bố"
        />
        <PermissionsGate
          name={PERMISSIONS_KEY.AnnounceInformation}
          action={PERMISSIONS_ACTIONS.CREATE}
        >
          <Link to={'/announced-information-management/add'}>
            <button
              type="button"
              className="flex justify-center w-full md:w-52 form-button"
            >
              Tạo Thông Tin Mới
            </button>
          </Link>
        </PermissionsGate>
      </div>
      <div className="mt-10">
        <div className="flex">
          <div className="flex w-1/2 space-x-2">
            <SearchButton
              onSearch={onSearch}
              keyword={keyword}
              setKeyword={setKeyword}
              _handleKeyDown={_handleKeyDown}
            />
            <div className="space-x-2">
              <FilterButton toggleFilter={toggleFilter} />
            </div>
          </div>
          <div className="ml-auto mt-2">
            <ItemsPerPage
              choice={itemPerPage}
              setChoice={(val: number) => {
                handlePageSizeChange(val);
              }}
            />
          </div>
        </div>
        <div className="w-full h-auto relative mt-2 overflow-auto rounded shadow">
          <table className="table w-full rounded-lg">
            <thead className="bg-gray-f2 border-b-2 border-gray-200">
              <tr>
                <th style={{ width: '60px' }}>STT</th>
                <th>
                  <span>ID</span>
                </th>
                <th>
                  <span>Tiêu đề</span>
                </th>
                <th>
                  <span>Nhà cung cấp</span>
                </th>
                <th>
                  <span>Loại thông báo</span>
                </th>
                <th>
                  <span>Ngày đăng</span>
                </th>
                <th>
                  <span>Tài liệu đính kèm</span>
                </th>
                <th></th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {annoncement?.data?.items?.map((item: any, index: number) => (
                  <tr key={item?.id}>
                    <td>{(currentPage - 1) * itemPerPage + index + 1}</td>
                    <td className="font-semibold max-w-[150px] overflow-scroll hidden-scrollbar">
                      <div className="w-[300px]">
                        <span>{item?.id || '-'}</span>
                      </div>
                    </td>
                    <td>
                      <span>{item?.title || '-'}</span>
                    </td>
                    <td>{item?.provider || '-'}</td>
                    <td>{announcementType(item?.announcement_type)}</td>
                    <td>
                      <span>
                        {item.public_date
                          ? moment(new Date(item.public_date)).format(
                              FORM_FORMAT.TABLE_DATE
                            )
                          : '-'}
                      </span>
                    </td>
                    <td>
                      <div className="max-w-[200px] max-h-[80px] overflow-scroll hidden-scrollbar">
                        <div className="flex flex-col gap-3 w-[400px]">
                          {item?.files?.map((file: any, index: number) => (
                            <a
                              className="text-primary-e2 hover:underline"
                              href={file?.file_url}
                              key={file?.id}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span className={`client`}>
                                {file?.display_name}
                              </span>
                            </a>
                          ))}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex gap-2">
                        <PermissionsGate
                          name={PERMISSIONS_KEY.AnnounceInformation}
                          action={PERMISSIONS_ACTIONS.UPDATE}
                        >
                          <div className="items-center text-base table-action-btn">
                            <Link
                              to={`/announced-information-management/edit/${item?.id}`}
                            >
                              <BiEdit className="text-0BA5EC text-lg" />
                            </Link>
                          </div>
                        </PermissionsGate>
                        <PermissionsGate
                          name={PERMISSIONS_KEY.AnnounceInformation}
                          action={PERMISSIONS_ACTIONS.DELETE}
                        >
                          <div className="flex gap-3 items-center text-base table-action-btn">
                            <div
                              onClick={() => {
                                setIdAnnouncement(item?.id);
                                setConfirmDeleteModal(true);
                              }}
                            >
                              <BiTrash className="text-red-3b text-lg" />
                            </div>
                          </div>
                        </PermissionsGate>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {annoncement?.data?.items?.length === 0 && !loading && (
          <p className="text-center text-sm mt-3">No announcement found</p>
        )}
        {annoncement?.data?.total_results > itemPerPage && !loading && (
          <div className="my-6 flex text-right">
            <PaginationOwn
              totalItems={annoncement?.data?.total_results}
              itemPerPage={itemPerPage}
              pageChange={onPageChange}
              pageCurrent={currentPage}
            />
          </div>
        )}
        {loading && (
          <>
            <div className="flex justify-center mt-4 items-center tex-sm">
              <CircleSpin color="text-primary-e2" /> Loading...
            </div>
          </>
        )}
      </div>
      <PanelSiderBar
        open={openedFilter}
        close={() => {
          setOpenedFilter(false);
        }}
        submit={handleSubmit(submitHandler)}
        clear={clearFilter}
      >
        <form>
          <div className="form-group">
            <div className="">
              <label className="">Loại thông báo</label>
              <select
                className="form-control mt-1"
                placeholder="---"
                {...register('announcement_type', {
                  setValueAs: (value: string) => value?.trim(),
                  onChange: () => {
                    trigger('announcement_type');
                  }
                })}
              >
                <option value="">{'Tất cả'}</option>
                <option value="Announcement">Công bố thông tin</option>
                <option value="FinancialReport">Báo cáo tài chính</option>
                <option value="CompanyDocument">Tài liệu công ty</option>
                <option value="DocumentReport">Tài liệu báo cáo</option>
                <option value="SbbsReport">Thông báo</option>
                <option value="MarginSecurity">Giao Dịch Ký Quỹ</option>
              </select>
            </div>
          </div>
          <div className="">
            <label>Ngày đăng</label>
            <div className="flex form-group space-x-2">
              <div className="w-1/2">
                <DatePicker
                  selected={fromDate}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date: any) => {
                    setStart(date);
                  }}
                  placeholderText="Từ"
                  className="form-control"
                />
              </div>
              <div className="w-1/2">
                <DatePicker
                  selected={toDate}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date: any) => {
                    setEnd(date);
                  }}
                  placeholderText="Đến"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </form>
      </PanelSiderBar>
      <Modal
        show={confirmDeleteModal}
        title={'Xác Nhận Xoá'}
        onClose={() => {
          setConfirmDeleteModal(false);
        }}
        className="max-w-[420px]"
      >
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex justify-center w-full md:w-52 form-button-seconday"
            onClick={() => {
              setConfirmDeleteModal(false);
            }}
          >
            Huỷ
          </button>
          <button
            type="submit"
            className="flex justify-center w-full md:w-52 form-button"
            disabled={submitDelete}
            onClick={() => {
              handleDeleteAnnouncement(idAnnouncement);
            }}
          >
            {submitDelete && <CircleSpin />}
            Đồng ý
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AnnouncedInformationPage;
