import React from 'react';
import Routes from 'routes/Routes';
import AuthProvider from 'contexts/Auth/AuthProvider';
import ContainerLayout from 'layouts/ContainerLayout/ContainerLayout';
import { ToastContainer } from 'react-toastify';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import 'react-toastify/dist/ReactToastify.css';

type AppProps = {
  pca: IPublicClientApplication;
};

const App = ({ pca }: AppProps) => (
  <div>
    <MsalProvider instance={pca}>
      <AuthProvider>
        <ContainerLayout>
          <Routes />
          <ToastContainer hideProgressBar={true} />
        </ContainerLayout>
      </AuthProvider>
    </MsalProvider>
  </div>
);

export default App;
