import Breadcrumb from 'components/Breadcrumb';
import { PaginationOwn } from 'components/Shared';
import TitlePage from 'components/TitlePage';
import { CircleSpin, ItemsPerPage } from 'components/UiComponents';
import React, { useCallback, useEffect, useState } from 'react';
import { GiTwoCoins } from 'react-icons/gi';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getWithdrawsAsync,
  selectWithdraws
} from 'store/slices/withdraws/withdrawsSlice';
import { useForm, SubmitHandler } from 'react-hook-form';
import PanelSiderBar from 'components/UiComponents/PanelSiderBar/PanelSiderBar';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FilterButton from 'components/UiComponents/FilterButton/FilterButton';
import SearchButton from 'components/UiComponents/SearchButton/SearchButton';
import { FORM_FORMAT } from 'constant/form.constant';

const ApproveWithdraw = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { withdraws } = useAppSelector(selectWithdraws);
  const [crumbs] = useState([
    { name: 'Giao Dịch Cần Duyệt', url: '/approve-withdraw-management' }
  ]);
  const selected = () => {
    return;
  };
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useAppDispatch();
  const [itemPerPage, setItemPerPage] = useState(20);
  const [keyword, setKeyword] = useState<string>();
  const [filters, setFilters] = useState<any>({});
  const [openedFilter, setOpenedFilter] = useState(false);
  const [fromDate, setStart] = useState<any>();
  const [toDate, setEnd] = useState<any>();
  const [reloadPage, setReloadPage] = useState<any>();

  const page = searchParams.get('page') || 1;

  const onPageChange = useCallback(async (event: number) => {
    navigate(`/approve-withdraw-management?page=${event}`);
  }, []);

  const { register, handleSubmit, trigger, reset } = useForm<any>();

  const getData = async (params: any) => {
    setLoading(true);
    await dispatch(getWithdrawsAsync(params));
    setLoading(false);
  };

  useEffect(() => {
    if (page) {
      setCurrentPage(Number(page));
      let params: any = {
        page_size: itemPerPage,
        page_index: page,
        status: 'PendingApproval',
        ...filters
      };
      if (keyword) {
        const trimKeywork = keyword.trim();
        params = {
          ...params,
          keyword: trimKeywork
        };
      }
      getData(params);
    }
  }, [page, itemPerPage, filters, reloadPage]);

  const toggleFilter = () => {
    setOpenedFilter(!openedFilter);
  };

  const submitHandler: SubmitHandler<any> = async value => {
    let filerValues = value;

    if (filerValues.status === '') {
      delete filerValues['status'];
    }

    if (filerValues.min_amount === '') {
      delete filerValues['min_amount'];
    }

    if (filerValues.max_amount === '') {
      delete filerValues['max_amount'];
    }

    if (fromDate) {
      const from_date = moment(fromDate)
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      filerValues = {
        ...filerValues,
        from_date
      };
    }
    if (toDate) {
      const to_date = moment(toDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      filerValues = {
        ...filerValues,
        to_date
      };
    }

    setOpenedFilter(false);
    navigate(`/approve-withdraw-management`);
    setFilters(filerValues);
  };

  const onSearch = (reset?: boolean) => {
    const newKeywork = reset ? '' : keyword?.trim();
    setKeyword(newKeywork);
    setReloadPage(!reloadPage);
    navigate(`/approve-withdraw-management`);
  };

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setKeyword(e.target.value);
      onSearch();
    }
  };

  const handlePageSizeChange = (value: any) => {
    navigate(`/approve-withdraw-management`);
    setItemPerPage(value);
  };

  const clearFilter = () => {
    reset();
    setStart('');
    setEnd('');
    setFilters({});
    setOpenedFilter(false);
    navigate(`/approve-withdraw-management`);
  };

  return (
    <>
      <Breadcrumb crumbs={crumbs} selected={selected} />
      <TitlePage icon={() => <GiTwoCoins />} name="Giao Dịch Cần Duyệt" />
      {
        <div className="mt-10">
          <div className="flex">
            <div className="flex w-1/2 space-x-2 mb-4">
              <SearchButton
                onSearch={onSearch}
                keyword={keyword}
                setKeyword={setKeyword}
                _handleKeyDown={_handleKeyDown}
              />
              <div className="space-x-2">
                <FilterButton toggleFilter={toggleFilter} />
              </div>
            </div>
            <div className="ml-auto mt-2 flex">
              <div>
                <ItemsPerPage
                  choice={itemPerPage}
                  setChoice={(val: number) => handlePageSizeChange(val)}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-auto relative overflow-auto rounded shadow">
            <table className="table w-full rounded-lg">
              <thead className="bg-gray-f2 border-b-2 border-gray-200">
                <tr>
                  <th scope="col">
                    <span>STT</span>
                  </th>
                  <th scope="col">
                    <span>Mã Lệnh</span>
                  </th>
                  <th scope="col">
                    <span>Mã KH</span>
                  </th>
                  <th scope="col">
                    <span>Tên KH</span>
                  </th>
                  <th scope="col">
                    <span>{`Tiền Rút(đ)`}</span>
                  </th>
                  <th scope="col">
                    <span>{`Tổng Phí(đ)`}</span>
                  </th>
                  <th scope="col">
                    <span>{`Tiền Thực Nhận(đ)`}</span>
                  </th>
                  <th scope="col">
                    <span>Ngày Rút</span>
                  </th>
                  <th scope="col">
                    <span>Trạng Thái</span>
                  </th>
                  {/* <th scope="col"><span></span></th> */}
                </tr>
              </thead>
              {!loading && (
                <tbody>
                  {withdraws?.data?.items?.map(
                    (withdraw: any, index: number) => (
                      <tr key={withdraw.id}>
                        <th>{(currentPage - 1) * itemPerPage + index + 1}</th>
                        <td>
                          <div className="max-w-[250px] overflow-scroll hidden-scrollbar">
                            <div>
                              <div className="w-[400px]">
                                <p>
                                  Transfer ID:{` `}
                                  {withdraw?.transfer_id ? (
                                    <Link
                                      to={`${withdraw?.id}`}
                                      className={` text-primary-e2 hover:underline`}
                                    >
                                      <span className="order">
                                        {withdraw?.transfer_id}
                                      </span>
                                    </Link>
                                  ) : (
                                    // <span className="order">{withdraw?.transfer_id}</span>
                                    <span className={`text-center`}>-</span>
                                  )}
                                </p>
                              </div>
                              <div>
                                <p>
                                  SBBS Transfer ID:{` `}
                                  {withdraw?.sbbs_transfer_id ? (
                                    // <Link
                                    //     to={withdraw?.sbbs_transfer_id}
                                    //     className={` text-primary-e2 hover:underline`}
                                    //     >
                                    //         <span className="order">
                                    //             {withdraw?.sbbs_transfer_id}
                                    //         </span>
                                    // </Link>
                                    <span className="order">
                                      {withdraw?.sbbs_transfer_id}
                                    </span>
                                  ) : (
                                    <span className={`text-center`}>-</span>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          {/* <Link
                          className="text-primary-e2 hover:underline"
                          to={`/account-management/${withdraw?.account_no}/edit/user`}
                        >
                          <span>{withdraw?.account_no || "-"}</span>
                        </Link> */}
                          <span>{withdraw?.account_no || '-'}</span>
                        </td>
                        <td>
                          <span>{withdraw?.fullname}</span>
                        </td>
                        <td>
                          <span>
                            <CurrencyFormat
                              value={withdraw?.transfer_amount_request}
                              thousandSeparator={true}
                              displayType={'text'}
                            />
                          </span>
                        </td>
                        <td>
                          <span>
                            <CurrencyFormat
                              value={withdraw?.transfer_fee}
                              thousandSeparator={true}
                              displayType={'text'}
                            />
                          </span>
                        </td>
                        <td>
                          <span>
                            <CurrencyFormat
                              value={withdraw?.transfer_amount_request}
                              thousandSeparator={true}
                              displayType={'text'}
                            />
                          </span>
                        </td>
                        <td className="order">
                          <span>
                            {moment(new Date(withdraw?.created_date)).format(
                              FORM_FORMAT.TABLE_DATETIME
                            )}
                          </span>
                        </td>
                        <td>
                          <span className={`status status-${withdraw?.status}`}>
                            {withdraw?.status}
                          </span>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              )}
            </table>
          </div>
          {(withdraws?.data?.items?.length <= 0 || !withdraws?.data?.items) &&
            !loading && (
              <p className="text-center text-sm mt-3">No Withdraw found</p>
            )}
          {withdraws?.data?.total_results > itemPerPage && !loading && (
            <div className="my-6 d-flex text-right">
              <PaginationOwn
                totalItems={withdraws?.data?.total_results}
                itemPerPage={itemPerPage}
                pageChange={onPageChange}
                pageCurrent={currentPage}
              />
            </div>
          )}
          {loading && (
            <div className="flex justify-center items-center mt-4 text-sm">
              <CircleSpin color="text-primary-e2" /> Loading...
            </div>
          )}
        </div>
      }
      <PanelSiderBar
        open={openedFilter}
        close={toggleFilter}
        submit={handleSubmit(submitHandler)}
        clear={clearFilter}
      >
        <form>
          <div className="form-group">
            <div className="mt-4">
              <label htmlFor="">Tiền Rút Tối Thiếu</label>
              <input
                type="number"
                placeholder="Min"
                className="form-control mt-1"
                onWheel={e => e.currentTarget.blur()}
                {...register('min_amount', {
                  setValueAs: (value: string) => value,
                  onChange: () => {
                    trigger('min_amount');
                  }
                })}
              />
            </div>
            <div className="mt-4">
              <label htmlFor="">Tiền Rút Tối Đa</label>
              <input
                type="number"
                placeholder="Max"
                className="form-control mt-1"
                onWheel={e => e.currentTarget.blur()}
                {...register('max_amount', {
                  setValueAs: (value: string) => value,
                  onChange: () => {
                    trigger('max_amount');
                  }
                })}
              />
            </div>
          </div>
          <div className="">
            <label className="text-sm">Ngày rút tiền</label>
            <div className="flex form-group space-x-2">
              <div className="w-1/2">
                <DatePicker
                  selected={fromDate}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date: any) => {
                    setStart(date);
                  }}
                  placeholderText="Từ"
                  className="form-control"
                />
              </div>
              <div className="w-1/2">
                <DatePicker
                  selected={toDate}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date: any) => {
                    setEnd(date);
                  }}
                  placeholderText="Đến"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </form>
      </PanelSiderBar>
    </>
  );
};

export default ApproveWithdraw;
