import React from 'react'

const useSortableData = (config: any = null) => {
  const [sortConfig, setSortConfig] = React.useState<any>(config)

  const requestSort = (key: any, direction?: string) => {
    let _direction = direction ? direction : 'Ascending'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'Ascending') {
      _direction = 'Descending'
    }
    setSortConfig({ key, direction: _direction })
  }

  return { requestSort, sortConfig }
}

export default useSortableData