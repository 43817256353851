import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import MasterLayout from 'layouts/MasterLayout/';
import LoadingBarProcess from 'components/LoadingBarProcess';

const AccountManagementPage = React.lazy(
  () => import('./../pages/AccountManagement')
);
const AccountDetailsEditPage = React.lazy(
  () => import('./../pages/AccountManagement/AccountDetailsEdit')
);

const AccountManagementRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout type="header" isBack={false} />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AccountManagementPage />
            </React.Suspense>
          }
        />
        <Route
          path=":id/edit/account"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AccountDetailsEditPage />
            </React.Suspense>
          }
        />
        <Route
          path=":id/edit/ekyc"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AccountDetailsEditPage />
            </React.Suspense>
          }
        />
        <Route
          path=":id/edit/document"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AccountDetailsEditPage />
            </React.Suspense>
          }
        />
        <Route
          path=":id/edit/user"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AccountDetailsEditPage />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default AccountManagementRoutes;
