import { HOST_PROMOTION_URL, NEW_PROMOTION_MANAGEMENT } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getEventPackageById = (id: string) => {
    return axiosInstance.get(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.GET_EVENT_PACKAGES_BY_ID(id));
}

export const addEventPackage = (params: any) => {
    return axiosInstance.post(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.ADD_EVENT_PACKAGE, params);
}

export const updateEventPackageById = (params: any) => {
    return axiosInstance.put(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.UPDATE_EVENT_PACKAGES_BY_ID(params.id), params);
}

export const enableEventPackageById = (id: string) => {
    return axiosInstance.post(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.ENABLE_EVENT_PACKAGE_BY_ID(id));
}

export const disableEventPackageById = (id: string) => {
    return axiosInstance.post(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.DISABLE_EVENT_PACKAGE_BY_ID(id));
}