import { Configuration, PopupRequest } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: '3fd0082d-734e-46c0-8ff1-aa6e2677f15b',
    authority:
      'https://login.microsoftonline.com/e68e11b5-321b-4bdf-9a50-b1fc0902810b',
    redirectUri: '/ms-callback',
    postLogoutRedirectUri: '/'
  },
  system: {
    allowNativeBroker: false // Disables WAM Broker
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['User.Read']
};
