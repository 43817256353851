import { HOST_PROMOTION_URL, NEW_PROMOTION_MANAGEMENT } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getEventItemById = (id: string) => {
    return axiosInstance.get(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.GET_EVENT_ITEMS_BY_ID(id));
}

export const addEventItem = (params: any) => {
    return axiosInstance.post(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.ADD_EVENT_ITEM, params);
}

export const updateEventItemById = (params: any) => {
    return axiosInstance.put(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.UPDATE_EVENT_ITEMS_BY_ID(params.id), params);
}

export const enableEventItemById = (id: string) => {
    return axiosInstance.post(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.ENABLE_EVENT_ITEM_BY_ID(id));
}

export const disableEventItemById = (id: string) => {
    return axiosInstance.post(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.DISABLE_EVENT_ITEM_BY_ID(id));
}