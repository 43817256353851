import { HOST_PROMOTION_URL, HOST_URL, NEW_PROMOTION_MANAGEMENT } from "constant/api.contant";
import axiosInstance from "services/restful.service";

export const getNewPromotionEvents = (params: any) => {
    return axiosInstance.get(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.GET_PROMOTION_EVENTS, {params});
}

export const getNewPromotionEventById = (id: string) => {
    return axiosInstance.get(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.GET_PROMOTION_EVENT_BY_ID(id));
}

export const addNewPromotionEvent = (params: any) => {
    return axiosInstance.post(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.ADD_PROMOTION_EVENT, params);
}

export const updateNewPromotionEventById = (params: any) => {
    return axiosInstance.put(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.UPDATE_PROMOTION_EVENT_BY_ID(params.id), params);
}

export const enableNewPromotionEventById = (id: string) => {
    return axiosInstance.post(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.ENABLE_PROMOTION_EVENT_BY_ID(id));
}

export const disableNewPromotionEventById = (id: string) => {
    return axiosInstance.post(HOST_PROMOTION_URL + NEW_PROMOTION_MANAGEMENT.DISABLE_PROMOTION_EVENT_BY_ID(id));
}

export const uploadBannerPromotionEventFile = (file: any) => {
  const formData = new FormData();
  formData.append(
    "file",
    file,
  );
  return axiosInstance
  .post(`${HOST_URL}${NEW_PROMOTION_MANAGEMENT.PROMOTION_EVENT_UPLOAD_BANNER}/1`, formData);  
}

export const uploadIconPromotionEventFile = (file: any) => {
  const formData = new FormData();
  formData.append(
    "file",
    file,
  );
  return axiosInstance
  .post(`${HOST_URL}${NEW_PROMOTION_MANAGEMENT.PROMOTION_EVENT_UPLOAD_BANNER}/2`, formData);  
}