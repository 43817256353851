import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import {
  activeAccountById,
  cashBalanceAccountById,
  getAccountById,
  getAccounts,
  lockAccountById,
  portfolioAccountById,
  updateAccountById
} from './accountsAPI';
export interface AccountsState {
  accounts: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: AccountsState = {
  accounts: [],
  status: 'idle'
};

export const getAccountsAsync = createAsyncThunk(
  'accounts/getAccounts',
  async (params?: any) => {
    const response = await getAccounts(params);
    return response.data;
  }
);

export const getAccountByIdAsync = createAsyncThunk(
  'accounts/getAccounts',
  async (params?: any) => {
    const response = await getAccountById(params);
    return response.data;
  }
);

export const updateAccountDetailsAsync = createAsyncThunk(
  'accounts/updatedAccountDetails',
  async (params?: any) => {
    try {
      const response = await updateAccountById(params);
      return response.data;
    } catch (error: any) {
      return { error: error.response?.data };
    }
  }
);

export const activeAccountByIdAsync = createAsyncThunk(
  'accounts/activeAccountById',
  async (params?: any) => {
    try {
      const response = await activeAccountById(params);
      return response.data;
    } catch (error: any) {
      return { error: error.response?.data };
    }
  }
);

export const lockAccountByIdAsync = createAsyncThunk(
  'accounts/lockAccountById',
  async (params?: any) => {
    try {
      const response = await lockAccountById(params);
      return response.data;
    } catch (error: any) {
      return { error: error.response?.data };
    }
  }
);

export const portfolioAccountByIdAsync = createAsyncThunk(
  'accounts/portfolioAccountById',
  async (params?: any) => {
    const response = await portfolioAccountById(params);
    return response.data;
  }
);

export const cashBalanceAccountByIdAsync = createAsyncThunk(
  'accounts/cashBalanceAccountById',
  async (params?: any) => {
    const response = await cashBalanceAccountById(params);
    return response.data;
  }
);

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    getList: (state, action: PayloadAction<undefined>) => {
      state.accounts = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAccountsAsync.pending, state => {
        state.status = 'loading';
      })
      .addCase(getAccountsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.accounts = action.payload?.data;
      });
  }
});

export const { getList } = accountsSlice.actions;
export const selectAccounts = (state: RootState) => state.accouts;

export default accountsSlice.reducer;
