import { DAY_END_LOGS, HOST_URL, HOST_URL_BIZ } from 'constant/api.contant';
import axiosInstance from './restful.service';

const getDELogs = (params?: any) => {
  return axiosInstance
    .get(HOST_URL + DAY_END_LOGS.LIST, { params })
    .then(res => {
      const data = res?.data;
      return data?.data;
    });
};

const startDE = () => {
  return axiosInstance.post(HOST_URL + DAY_END_LOGS.START_DE).then(res => {
    const data = res?.data;
    return data;
  });
};

const endDE = () => {
  return axiosInstance.post(HOST_URL + DAY_END_LOGS.END_DE).then(res => {
    const data = res?.data;
    return data;
  });
};

const getDEStatus = () => {
  return axiosInstance.get(HOST_URL_BIZ + DAY_END_LOGS.DE_STATUS).then(res => {
    const data = res?.data;
    return data?.data;
  });
};

export const delogsService = {
  getDELogs,
  startDE,
  endDE,
  getDEStatus
};
