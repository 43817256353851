import { HOST_URL, ACCOUNTS } from 'constant/api.contant';
import axiosInstance from "services/restful.service";

export const getAccounts = (params?: any) => {
  return axiosInstance
  .get(HOST_URL + ACCOUNTS.LIST, {params});
}

export const getAccountById = (params?: any) => {
  return axiosInstance
  .get(HOST_URL + ACCOUNTS.DETAILS(params.id));
}

export const updateAccountById = (params?: any) => {
  return axiosInstance
  .put(HOST_URL + ACCOUNTS.UPLOAD_ACCOUNT(params.id), params);
}

export const exportAccountExcel = (params?: any) => {
  const url = `${HOST_URL}${ACCOUNTS.EXPORT_EXCEL}?${params?.keysearch ? `keysearch=${params?.keysearch}&` : ``}${params?.ekyc_status ? `ekyc_status=${params?.ekyc_status}&` : ``}${params?.from_date ? `from_date=${params?.from_date}&` : ``}${params?.to_date ? `to_date=${params?.to_date}&` : ``}${params?.page_size ? `page_size=${params?.page_size}&` : ``}`;
  
  return axiosInstance
  .post(url, {...params}, {responseType: 'blob'});
};

export const activeAccountById = (id?: any) => {
  return axiosInstance
    .post(HOST_URL + ACCOUNTS.ACTIVE_ACCOUNT(id));
}

export const lockAccountById = (id?: any) => {
  return axiosInstance
    .post(HOST_URL + ACCOUNTS.LOCK_ACCOUNT(id));
}

export const portfolioAccountById = (id?: any) => {
  return axiosInstance
    .get(HOST_URL + ACCOUNTS.FORTFOLIO(id));
}

export const cashBalanceAccountById = (id?: any) => {
  return axiosInstance
    .get(HOST_URL + ACCOUNTS.CASH_BALANCE(id));
}

export const uploadFile = (file: any) => {
  const formData = new FormData();
  formData.append(
    "files",
    file,
  );
  return axiosInstance
  .post(`${HOST_URL}${ACCOUNTS.UPLOAD}`, formData);  
}

export const uploadAvatarFile = (file: any) => {
  const formData = new FormData();
  formData.append(
    "file",
    file,
  );
  return axiosInstance
  .post(`${HOST_URL}${ACCOUNTS.UPLOAD_AVATAR}`, formData);  
}

export const getClientById = (id: string) => {
  return axiosInstance
    .get(HOST_URL + ACCOUNTS.CLIENT(id));
}

export const syncClientG3 = (id: string) => {
  return axiosInstance
    .post(HOST_URL + ACCOUNTS.SYNC_CLIENT(id));
}
