import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getRewardUsers } from "./NewPromotionUserAPI";

export interface NewPromotionUserState {
    newPromotionUser: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: NewPromotionUserState = {
    newPromotionUser: [],
    status: 'idle',
}

export const getRewardUsersAsync = createAsyncThunk(
    'newPromotionUser/getRewardUsers',
    async (params: any) => {
        const response = await getRewardUsers(params);
        return response.data;
    }
);

export const NewPromotionUserSlice = createSlice({
    name: "newPromotionUser",
    initialState,
    reducers: {
       getList: (state, action: PayloadAction<undefined>) => {
            state.newPromotionUser = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRewardUsersAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getRewardUsersAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.newPromotionUser = action.payload;
            })
    },
});

export const { getList} = NewPromotionUserSlice.actions;

export const selectNewPromotionUser = (state: RootState) => state.newPromotionUser;

export default NewPromotionUserSlice.reducer;