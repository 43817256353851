import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { getDeposits} from "./depositsAPI";

export interface DepositsStage {
    deposits: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: DepositsStage = {
    deposits: [],
    status: 'idle',
}

export const getDepositsAsync = createAsyncThunk(
    'deposits/getDeposits',
    async (params: any) => {
        const response = await getDeposits(params);
        return response.data;
    }
);

export const depositsSlice = createSlice({
    name: "deposits",
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
            state.deposits = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDepositsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getDepositsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.deposits = action.payload;
            })
    },
});

export const { getList} = depositsSlice.actions;
export const selectDeposits = (state: RootState) => state.deposits;

export default depositsSlice.reducer;