import { HOST_URL, WITHDRAWS } from "constant/api.contant"
import axiosInstance from "services/restful.service"


export const getWithdraws = (params?: any) => {
    return axiosInstance.get(HOST_URL + WITHDRAWS.LIST, {params});
}

export const getWithdrawDetail = (id: string) => {
    return axiosInstance.get(HOST_URL + WITHDRAWS.DETAILS(id));
}

export const approveWithDraw = (id: string) => {
    return axiosInstance.post(HOST_URL + WITHDRAWS.APPROVED(id));
}