import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { 
    addEventStage,
    disableEventStageById,
    enableEventStageById,
    getEventStageById, 
    updateEventStageById
} from "./NewPromotionStageAPI";

export interface NewPromotionStageState {
    newPromotionStage: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: NewPromotionStageState = {
    newPromotionStage: [],
    status: 'idle',
}

export const getEventStageByIdAsync = createAsyncThunk(
    'newPromotionStage/getEventStageById',
    async (params: any) => {
        const response = await getEventStageById(params);
        return response.data;
    }
);

export const addEventStageAsync = createAsyncThunk(
    'newPromotionStage/addEventStage',
    async (params: any) => {
        try {
            const response = await addEventStage(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const updateEventStageByIdAsync = createAsyncThunk(
    'newPromotionStage/updateEventStageById',
    async (params: any) => {
        try {
            const response = await updateEventStageById(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const enableEventStageByIdAsync = createAsyncThunk(
    'newPromotionStage/enableEventStageById',
    async (id: string) => {
        try {
            const response = await enableEventStageById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const disableEventStageByIdAsync = createAsyncThunk(
    'newPromotionStage/disableEventStageById',
    async (id: string) => {
        try {
            const response = await disableEventStageById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const NewPromotionStageSlice = createSlice({
    name: "newPromotionStage",
    initialState,
    reducers: {
       getList: (state, action: PayloadAction<undefined>) => {
            state.newPromotionStage = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEventStageByIdAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEventStageByIdAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.newPromotionStage = action.payload;
            })
    },
});

export const { getList} = NewPromotionStageSlice.actions;

export const selectNewPromotionStage = (state: RootState) => state.newPromotionStage;

export default NewPromotionStageSlice.reducer;