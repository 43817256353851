const WEB_PATH = process.env.REACT_APP_TTTD_PATH;
const KIEN_THUC_DAU_TU_ID = process.env.REACT_APP_KIEN_THUC_DAU_TU_ID || '';
const PHAN_TICH_NGAN_ID = process.env.REACT_APP_PHAN_TICH_NGAN_ID || '';
const THUAT_NGU_DAU_TU_ID = process.env.REACT_APP_THUAT_NGU_DAU_TU_ID || '';

export const getCurrencyColor = (value: any) => {
    if (+value >= 0) {
        return 'text-black'
    }else {
        return 'text-red-FF7337'
    }
}

export const generateSlug = (text?: string): string => {
    if (!text) {
      return '';
    }
    const noAccentText = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D');
  
    return noAccentText
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
};

export const debounce = (func: any, wait: number) => {
  let timeout : any;

  return function executedFunction(...args : any) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const removeAccents = (str: string) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace('đ', 'D')
    .replace('Đ', 'D');
}

export const getArticlePath = (item: any) => {
  if (!item) return '/';
  
  const slug = generateSlug(item.title);

  let path = `${WEB_PATH}/kien-thuc/${slug}-ids-${item.id}`;

  if (item.category_id === KIEN_THUC_DAU_TU_ID) {
    path = `${WEB_PATH}/kien-thuc/kien-thuc-dau-tu/${slug}-ids-${item.id}`;
  }else if (item.category_id === PHAN_TICH_NGAN_ID) {
    path = `${WEB_PATH}/kien-thuc/tong-quan-ve-cong-ty/${slug}-ids-${item.id}`;
  }else if (item.category_id === THUAT_NGU_DAU_TU_ID) {
    const firstLetter = removeAccents(item.title[0].toLocaleLowerCase());
    path = `${WEB_PATH}/thuat-ngu-dau-tu/ttdt-${firstLetter}/${slug}-ids-${item.id}`;
  }else if (!item.category_id && item?.topic) {
    const topicLabel = JSON.parse(item?.topic)?.label;
    path = `${WEB_PATH}/faq/${generateSlug(topicLabel)}/${slug}-ids-${item.id}`;
  }

  return path;
}

export const DefaultColors = [
    '#BDDEF0',
    '#DFD4E9',
    '#D6E2E0',
    '#F7D2C2',
    '#E2D7C3',
    '#FEF8E6',
    '#FDF2CA',
    '#FFDFDF',
    '#C9F2B6',
]

export const formatCurrency = (number: number, symbol?: string) => {
  
  if (!number) {
    return '0';
  }
  if (!symbol) {
    symbol = ',';
  }
  const p = number.toFixed(2).split('.');
  return p[0]
    .split('')
    .reverse()
    .reduce(function (acc, num, i, orig) {
      return num + (num != '-' && i && !(i % 3) ? symbol : '') + acc;
    }, '');
};

