import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { addNewPromotionEvent, disableNewPromotionEventById, enableNewPromotionEventById, getNewPromotionEventById, getNewPromotionEvents, updateNewPromotionEventById } from "./NewPromotionEventAPI";

export interface NewPromotionEventState {
    newPromotionEvent: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: NewPromotionEventState = {
    newPromotionEvent: [],
    status: 'idle',
}

export const getNewPromotionEventsAsync = createAsyncThunk(
    'newPromotionEvent/getNewPromotionEvents',
    async (params: any) => {
        const response = await getNewPromotionEvents(params);
        return response.data;
    }
);

export const getNewPromotionEventByIdAsync = createAsyncThunk(
    'newPromotionEvent/getNewPromotionEventById',
    async (id: string) => {
        try {
            const response = await getNewPromotionEventById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const enableNewPromotionEventByIdAsync = createAsyncThunk(
    'newPromotionEvent/enableNewPromotionEventById',
    async (id: string) => {
        try {
            const response = await enableNewPromotionEventById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const disableNewPromotionEventByIdAsync = createAsyncThunk(
    'newPromotionEvent/disableNewPromotionEventById',
    async (id: string) => {
        try {
            const response = await disableNewPromotionEventById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const addNewPromotionEventAsync = createAsyncThunk(
    'newPromotionEvent/addNewPromotionEvent',
    async (params: any) => {
        try {
            const response = await addNewPromotionEvent(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const updateNewPromotionEventByIdAsync = createAsyncThunk(
    'newPromotionEvent/updateNewPromotionEventById',
    async (params: any) => {
        try {
            const response = await updateNewPromotionEventById(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const NewPromotionEventSlice = createSlice({
    name: "newPromotionEvent",
    initialState,
    reducers: {
        getList: (state, action: PayloadAction<undefined>) => {
            state.newPromotionEvent = action.payload;
        },
        toggleEventStatusByIdRedux: (state, action) => {
          const currentEvent = state.newPromotionEvent.data.items.find((event: any) => {
            return event.id === action.payload
          });
          if (currentEvent) {
            if (currentEvent.status === "Active") {
                currentEvent.status = "InActive";
                return;
            }

            if (currentEvent.status === "InActive") {
                currentEvent.status = "Active";
                return;
            }
          }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNewPromotionEventsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getNewPromotionEventsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.newPromotionEvent = action.payload;
            })
    },
});

export const { getList, toggleEventStatusByIdRedux} = NewPromotionEventSlice.actions;

export const selectNewPromotionEvent = (state: RootState) => state.newPromotionEvent;

export default NewPromotionEventSlice.reducer;