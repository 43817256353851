import { HOST_URL, USERS } from 'constant/api.contant';
import axiosInstance from "services/restful.service";

export const getUsers = (params?: any) => {
    return axiosInstance.get(HOST_URL + USERS.LIST, {params});
}

export const getUserById = (id: string) => {
    return axiosInstance.get(HOST_URL + USERS.GET_USER_BY_ID(id));
};

export const activeUserById = (id: string) => {
    return axiosInstance.put(HOST_URL + USERS.ACTIVE_USER(id));
}

export const deactiveUserById = (id: string) => {
    return axiosInstance.put(HOST_URL + USERS.DEACTIVE_USER(id));
}

export const lockUserById = (id: string) => {
    return axiosInstance.put(HOST_URL + USERS.LOCK_USER(id));
}

export const changeStatusEkycUserById = (params: any) => {
    return axiosInstance.put(HOST_URL + USERS.RE_EKYC(params?.id), params);
}

export const approveEkycUserById = (params: any) => {
    return axiosInstance.post(HOST_URL + USERS.APPROVE_EKYC(params.id), params);
}

export const getDepositsUserById = (params?: any) => {
    return axiosInstance.get(HOST_URL + USERS.GET_DEPOSIT_BY_USER(params.id), {params});
}

export const getWithdrawsUserById = (params?: any) => {
    return axiosInstance.get(HOST_URL + USERS.GET_WITHDRAW_BY_USER(params.id), {params});
}

export const restoreUserById = (id: string) => {
    return axiosInstance.post(HOST_URL + USERS.RESTORE_USER(id));
}

export const getFSInternalOrdersById = (params?: any) => {
    return axiosInstance.get(HOST_URL + USERS.GET_FS_INTERNAL_ORDERS(params.id), {params});
}

export const getFSStockAdvanceOrdersById = (params?: any) => {
    return axiosInstance.get(HOST_URL + USERS.GET_FS_STOCK_ADVANCE_ORDERS(params.id), {params});
}

export const getStockWaitingToReceiveById = (params?: any) => {
    return axiosInstance.get(HOST_URL + USERS.GET_STOCK_WAITING_TO_RECEIVE(params.id), {params});
}

export const getCashWaitingToReceiveById = (params?: any) => {
    return axiosInstance.get(HOST_URL + USERS.GET_CASH_WAITING_TO_RECEIVE(params.id), {params});
}

export const getBondTransactionsById = (params?: any) => {
    return axiosInstance.get(HOST_URL + USERS.GET_BOND_TRANSACTIONS_BY_USER(params.id), {params});
}

export const exportPDFContract = (params?: any) => {
    return axiosInstance.post(HOST_URL + USERS.GET_PDF_CONTRACT(params.user_id, params.contract_type), {params}, {responseType: 'blob'});
}