import React from "react";
import { useAppSelector } from "store/hooks";
import { selectThemeConfig } from "store/slices/ThemeConfigSlice";
import styles from "./index.module.scss";
import Sidebar from "layouts/Sidebar";

type ContainerLayoutProps = {
    children: React.ReactNode;
};

const ContainerLayout = ({ children }: ContainerLayoutProps) => {
    const { theme } = useAppSelector(selectThemeConfig);
    return (
        <div className="flex min-w-0 min-h-screen relative">
            {!theme?.layout?.sideNavCollapse && (
                <div className="lg:flex flex-col flex-none w-52 xl:w-70">
                    <div className={`${styles["masterlayoutside-navbar"]} flex-grow`}>
                        <Sidebar />
                    </div>
                </div>
            )}
            {children}
        </div>
    );
};

export default ContainerLayout;
