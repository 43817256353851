import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { 
    addEventItem,
    disableEventItemById,
    enableEventItemById,
    getEventItemById, 
    updateEventItemById
} from "./NewPromotionItemAPI";

export interface NewPromotionItemState {
    newPromotionItem: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: NewPromotionItemState = {
    newPromotionItem: [],
    status: 'idle',
}

export const getEventItemByIdAsync = createAsyncThunk(
    'newPromotionItem/getEventItemById',
    async (params: any) => {
        const response = await getEventItemById(params);
        return response.data;
    }
);

export const addEventItemAsync = createAsyncThunk(
    'newPromotionItem/addEventItem',
    async (params: any) => {
        try {
            const response = await addEventItem(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const updateEventItemByIdAsync = createAsyncThunk(
    'newPromotionItem/updateEventItemById',
    async (params: any) => {
        try {
            const response = await updateEventItemById(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const enableEventItemByIdAsync = createAsyncThunk(
    'newPromotionItem/enableEventItemById',
    async (id: string) => {
        try {
            const response = await enableEventItemById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const disableEventItemByIdAsync = createAsyncThunk(
    'newPromotionItem/disableEventItemById',
    async (id: string) => {
        try {
            const response = await disableEventItemById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const NewPromotionItemSlice = createSlice({
    name: "newPromotionItem",
    initialState,
    reducers: {
       getList: (state, action: PayloadAction<undefined>) => {
            state.newPromotionItem = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEventItemByIdAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEventItemByIdAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.newPromotionItem = action.payload;
            })
    },
});

export const { getList} = NewPromotionItemSlice.actions;

export const selectNewPromotionItem = (state: RootState) => state.newPromotionItem;

export default NewPromotionItemSlice.reducer;