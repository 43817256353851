import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { 
    addEventPackage,
    disableEventPackageById,
    enableEventPackageById,
    getEventPackageById, 
    updateEventPackageById
} from "./NewPromotionPackageAPI";

export interface NewPromotionPackageState {
    newPromotionPackage: any;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: NewPromotionPackageState = {
    newPromotionPackage: [],
    status: 'idle',
}

export const getEventPackageByIdAsync = createAsyncThunk(
    'newPromotionPackage/getEventPackageById',
    async (params: any) => {
        const response = await getEventPackageById(params);
        return response.data;
    }
);

export const addEventPackageAsync = createAsyncThunk(
    'newPromotionPackage/addEventPackage',
    async (params: any) => {
        try {
            const response = await addEventPackage(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const updateEventPackageByIdAsync = createAsyncThunk(
    'newPromotionPackage/updateEventPackageById',
    async (params: any) => {
        try {
            const response = await updateEventPackageById(params);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const enableEventPackageByIdAsync = createAsyncThunk(
    'newPromotionPackage/enableEventPackageById',
    async (id: string) => {
        try {
            const response = await enableEventPackageById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const disableEventPackageByIdAsync = createAsyncThunk(
    'newPromotionPackage/disableEventPackageById',
    async (id: string) => {
        try {
            const response = await disableEventPackageById(id);
            return response.data;
        } catch (error: any) {
            return {error: error.response?.data}
        } 
    }
);

export const NewPromotionPackageSlice = createSlice({
    name: "newPromotionPackage",
    initialState,
    reducers: {
       getList: (state, action: PayloadAction<undefined>) => {
            state.newPromotionPackage = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEventPackageByIdAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEventPackageByIdAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.newPromotionPackage = action.payload;
            })
    },
});

export const { getList} = NewPromotionPackageSlice.actions;

export const selectNewPromotionPackage = (state: RootState) => state.newPromotionPackage;

export default NewPromotionPackageSlice.reducer;