import LoadingBarProcess from 'components/LoadingBarProcess';
import { useAuth } from 'contexts/Auth/AuthContext';
import MasterLayout from 'layouts/MasterLayout';
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import ApproveWithdrawPage from "pages/ApproveWithdraw/ApproveWithdraw";
import ApproveWithdrawDetailPage from "pages/ApproveWithdraw/ApproveWithdrawDetail";

const ApproveWithdrawRoutes = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const hasRole = (roleUser: string, scopes: string[]) => {
        return scopes.includes(roleUser);
    }
    const scopesRole = [
        "Admin", 
        "SystemAdmin"
    ];
    useEffect(() => {
        if(auth?.user?.role) {
            const permissionGranted = hasRole(auth?.user?.role, scopesRole);

            if (!permissionGranted) navigate('/forbidden');

            if (permissionGranted) return;
        }
    }, [])
  return (
    <Routes>
        <Route path='/' element={<MasterLayout type='header'/>}>
            <Route 
                path=''
                element={
                    <React.Suspense fallback={<LoadingBarProcess />}>
                        <ApproveWithdrawPage />
                    </React.Suspense>
                }
            />
            <Route 
                path=':id'
                element={
                    <React.Suspense fallback={<LoadingBarProcess/>}>
                        <ApproveWithdrawDetailPage />
                    </React.Suspense>
                }
            />
        </Route>
    </Routes>
  )
}

export default ApproveWithdrawRoutes